@import "./style/index.less";

.BMap_cpyCtrl {
  display: none;
}

.anchorBL {
  display: none;
}

.App {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  .ant-layout-header,
  .ant-layout-header .ant-menu.ant-menu-dark {
    background-color: #317cec;
  }

  .ant-layout-sider-light {
    border-top: 8px solid @background;
  }

  .ant-layout-header {
    .ant-menu.ant-menu-dark {
      > li {
        color: #fff;
      }

      .ant-menu-item-selected {
        background-color: #1460d2;
      }
    }
  }

  .ka-wrapper {
    height: 100%;

    .ka-content {
      height: 100%;
    }
  }

  .tx-content {
    padding: 8px;
    box-sizing: border-box;
  }
  .app-content {
    flex-grow: 1;
    height: 0;
  }
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.ant-picker-cell-in-view {
  &.ant-picker-cell-range-start.ant-picker-cell-range-end {
    .ant-picker-cell-inner {
      background: #1890ff !important;
    }
  }

  &.ant-picker-cell-range-end {
    .ant-picker-cell-inner {
      background: #ff7a45 !important;
    }
  }
}

.amap-logo,
.amap-copyright {
  display: none !important;
}

.table-tree {
  width: 230px;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  .table-tree-query {
    > span {
      border-radius: 0;
    }
  }
  .table-treecontent {
    flex: 1;
    border: 1px solid #dfdfdf;
    border-top: none;
    overflow-y: auto;
  }
}

.map-crosshair {
  cursor: crosshair !important;
}
//国际化后字体调整
.en_Font_size {
  font-size: 12px !important;
  button {
    font-size: 11px !important;
  }
}

.tx-info-item-label-en {
  white-space: nowrap;
  overflow: hidden;
  text-align: left !important;
  display: inline-block !important;
}

.ant-form-item-label-en {
  .ant-form-item-label {
    margin-right: 7px;
    text-align: left;
  }
  label {
    text-align: left;

    white-space: nowrap;
  }
}
